define("ember-rollbar-client/instance-initializers/rollbar", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(appInstance) {
    let fastbootService = appInstance.lookup('service:fastboot');
    let rollbarService = appInstance.lookup('service:rollbar');
    let oldOnError = _ember.default.onerror || function () {};
    _ember.default.onerror = (...args) => {
      oldOnError(...args);
      let enabled = rollbarService.get('enabled');
      if (enabled) {
        rollbarService.error(...args);
      }
      if (!enabled || _ember.default.testing) {
        if (!fastbootService || !fastbootService.get('isFastBoot')) {
          throw args[0];
        }
      }
    };
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});