window.initMap = function () {
  // console.log('Google maps ready');
}

function getViewport () {
  const width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  )
  if (width <= 576) return 'xs'
  if (width <= 768) return 'sm'
  if (width <= 992) return 'md'
  if (width <= 1200) return 'lg'
  return 'xl'
}

window.initStripeMinimal = function (pubKey, connectedAccountId) {
  var data = {};
  if (connectedAccountId !== null && connectedAccountId !== undefined) {
    data['stripeAccount'] = connectedAccountId;
  }
  // console.log('Init Stripe with: ', data);
  var stripe = Stripe(pubKey, data);
  return { stripe };
};

window.initStripe = function (pubKey, clientSecret, connectedAccountId) {
  var data = {};
  if (connectedAccountId !== null && connectedAccountId !== undefined) {
    data['stripeAccount'] = connectedAccountId;
  }
  // console.log('Init Stripe with: ', data);
  var stripe = Stripe(pubKey, data);
  var cardElement = stripe.elements({
    clientSecret: clientSecret,
  });

  return { stripe, cardElement };
};

window.initPhoneInput = function (ENV, elementId, onBlur, reset, initOpts) {
  initOpts.utilsScript = '/assets/js/intl_tel_input_utils.js';
  // initOpts.onlyCountries = [
  //   "al", "ad", "at", "by", "be", "ba", "bg", "hr", "cz", "dk",
  //   "ee", "fo", "fi", "fr", "de", "gi", "gr", "va", "hu", "is", "ie", "it", "lv",
  //   "li", "lt", "lu", "mk", "mt", "md", "mc", "me", "nl", "no", "pl", "pt", "ro",
  //   "ru", "sm", "rs", "sk", "si", "es", "se", "ch", "ua", "gb"
  // ];
  initOpts.initialCountry = 'auto';
  initOpts.geoIpLookup = function (callback) {
    // fetch('https://ipinfo.io/?token=43f905d38123bd', {
    fetch('https://app.staging.corebase.io/proxy/ipi?token=43f905d38123bd', {
      headers: {
        'Accept': 'application/json'
      }})
      .then(response => response.json())
      .then(function(json) {
        console.log(json);
        callback(json && json.country ? json.country : 'us')
      })
      .catch(function (err) {
        console.log('Error getting location', err);
        callback('us');
      });

    // $.get('https://ipinfo.io/?token=43f905d38123bd', function () {}, 'jsonp').always(function (resp) {
    //   console.log(resp);
    //   var countryCode = resp && resp.country ? resp.country : 'us';
    //   console.log(countryCode);
    //   callback(countryCode);
    // });
  };
  var input = document.querySelector(elementId);
  let iti = window.intlTelInput(input, initOpts);

  // Validate on Blur
  input.addEventListener('blur', function () {
    onBlur(input, iti);
  });

  // on keyup / change flag: reset
  input.addEventListener('change', function () {
    reset(input, iti);
  });
  input.addEventListener('keyup', function () {
    reset(input, iti);
  });
};
